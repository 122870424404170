<template>
	<div id="content" class="container">
		<el-container>

			<Top></Top>
			<el-header>
				<Header :key="Date.now()" :headerTitle="headerTitle"></Header>
			</el-header>


			<StoreLogin></StoreLogin>

			<Attestation></Attestation>
			<MyRegister :register="register" @fromChild="isRegister"></MyRegister>

			<el-main>
				<!-- <keep-alive>
					<router-view></router-view>
				</keep-alive> -->
				<!-- {{ "keepAlive" + $route.meta.keepAlive }} -->
				<keep-alive>
					<router-view v-if="$route.meta.keepAlive"></router-view>
				</keep-alive>
				<!-- 除了需要缓存的路由 还要创建一个非缓存路由的入口-->
				<router-view v-if="!$route.meta.keepAlive"></router-view>
			</el-main>

			<el-footer>
				<Footer></Footer>
			</el-footer>
			<Suspension></Suspension>
		</el-container>
	</div>
</template>

<script>
	import {
		mapActions
	} from "vuex";
	import {
		mapGetters
	} from "vuex";

	export default {

		beforeUpdate() {
			this.activeIndex = this.$route.path;
		},
		data() {
			return {
				activeIndex: "", // 头部导航栏选中的标签
				search: "", // 搜索条件
				register: false, // 是否显示注册组件
				visible: false ,// 是否退出登录
				headerTitle:false,
			};
		},
		created() {
			if (localStorage.getItem("user")) {
				// 如果已经登录，设置vuex登录状态
				this.setUser(JSON.parse(localStorage.getItem("user")));
			}

		},
		computed: {
			...mapGetters(["getUser", "getNum"])
		},
		watch: {
			// 获取vuex的登录状态
			getUser: function(val) {
				if (val === "") {
					// 用户没有登录
					this.setShoppingCart([]);
				} else {
					// 用户已经登录,获取该用户的购物车信息
					// this.$axios
					// 	.post("/api/user/shoppingCart/getShoppingCart", {
					// 		user_id: val.user_id
					// 	})
					// 	.then(res => {
					// 		if (res.data.code === "001") {
					// 001 为成功, 更新vuex购物车状态
					// this.setShoppingCart(res.data.shoppingCartData);
					// 	} else {
					// 		// 提示失败信息
					// 		this.notifyError(res.data.msg);
					// 	}
					// })
					// .catch(err => {
					// 	return Promise.reject(err);
					// });
				}
			}
		},
		 mounted() {
		  },
		beforeDestroy() {
		    window.removeEventListener('scroll', this.handleScroll);
		},
		methods: {
			login() {
				// 点击登录按钮, 通过更改vuex的showLogin值显示登录组件
				this.setShowLogin(true);
			},
			// 退出登录
			logout() {
				this.visible = false;
				// 清空本地登录信息
				localStorage.setItem("user", "");
				// 清空vuex登录信息
				this.setUser("");
				this.notifySucceed("成功退出登录");
			},
			// 接收注册子组件传过来的数据
			isRegister(val) {
				this.register = val;
			},
			// 点击搜索按钮
			searchClick() {
				if (this.search != "") {
					// 跳转到全部商品页面,并传递搜索条件
					this.$router.push({
						path: "/goods",
						query: {
							search: this.search
						}
					});
					this.search = "";
				}
			}
		}
	};
</script>

<style lang="scss">
	* {
		padding: 0;
		margin: 0;
		border: 0;
		list-style: none;
	}

	#app {
		// background: #f8f9fe;
	}

	#app .el-header {
		margin-top: 30px;
		padding: 0;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.149019607843137);
		height: 129px !important;
		z-index: 99;
		background-color: #fff;
	}

	#app .el-main {
		min-height: 550px;
		min-width: 1100px;
		// margin: 0 auto;
		// background: #f8f9fe;
		display: flex;
		justify-content: center;
		padding: 0;
		background-color: #FFFFFF;
		overflow: hidden;
	}

	 .el-footer {
		padding: 0;
	}

	a,
	a:hover {
		text-decoration: none;
		color: #333;
	}
	#app .bg-box{
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../assets/imgs/u305.png) no-repeat;
		background-size:911px 310px;
		background-position:0% 0% !important;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 1!important;
	}
</style>
